<template>
    <div :style="{backgroundColor: !state.currentPath.includes('item') && !state.currentPath.includes('order') ? '#fff' : 'transparent'}" class="header-container">
        <div>
          <div @click="toHome" class="restaurant-container">
              <div :style="{marginBottom: state.isMobile ? -1 + 'vw' : -0.25 + 'rem'}" v-if="props.isLoaded">
                <img v-if="props.showLogo" :src="`data:image/jpeg;base64, ${imageSrc}`">
                <img class="no-image-logo" :src="noImage" v-if="!state.orgInfo?.logo || state.orgInfo?.logo === '' && props.showLogo">
              </div>
              <div class="img-loading" v-else>
              </div>
              <span :class="[!props.isLoaded ? 'text-loading' : '']" v-if="props.showRestaurantName" :style="{ fontSize: state.isMobile && props.isLoaded ? state.dynamicFontSize : 2 + 'rem' }"><span>{{ state.restaurantName }}</span></span>
          </div>
           <u-icon-rounded class="cursor" v-if="props.showBackBtn" :type="'back'" @click="toHomeOrPrevious" :iconWidth="0.75" :iconUnit="'rem'" :iconMnRt="0.15" :iconMnUnit="'rem'" />
        </div>
        <div v-if="!state.currentPath.includes('item') || !state.isMobile" class="icons-container">
            <u-icon v-if="state.currentPath === '/'" :type="'categories'" @click="emits('click-categories')" :showText="state.showText" :iconWidth="!state.isMobile ? 1.7 : 6" :iconUnit="!state.isMobile ? 'rem' : 'vw'" />
            <u-icon v-if="state.isFavEmpty" :isEmpty="state.isFavEmpty" :type="'favorite'" :showText="state.showText" @click="toFavorites" :iconWidth="!state.isMobile ? 2.6 : 8.7" :iconUnit="!state.isMobile ? 'rem' : 'vw'" />
            <u-icon v-else :isEmpty="state.isFavEmpty" :type="'favorite'" :showText="state.showText" @click="toFavorites" :iconWidth="!state.isMobile ? 2 : 6.8" :iconUnit="!state.isMobile ? 'rem' : 'vw'"/>
            <u-icon v-if="state.isOrdersNotEmpty" :isEmpty="state.isOrdersNotEmpty" :type="'orders'" :showText="state.showText" @click="toOrders" :iconWidth="!state.isMobile ? 1.85 : 6.4" :iconUnit="!state.isMobile ? 'rem' : 'vw'" :iconPdBt="!state.isMobile ? 0.1 : 0.25"  :iconPdUnit="!state.isMobile ? 'rem' : 'vw'" />
            <u-icon v-else :isEmpty="state.isOrdersNotEmpty" :type="'orders'" :showText="state.showText" @click="toOrders" :iconWidth="!state.isMobile ? 1.85 : 6.25" :iconUnit="!state.isMobile ? 'rem' : 'vw'" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { delay } from '@/globalFunctions'
import { onBeforeMount, reactive, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { eventBus } from '@/eventBus'
import { Organization } from '../../store/organization'
import noImage from '../../assets/images/no_image.svg'
import { useImagesYarosStore } from '@/store/images'

const props = defineProps<{
  showText?: boolean,
  showRestaurantName?: boolean,
  showLogo?: boolean,
  showBackBtn?: boolean,
  isFavEmpty?: boolean,
  isLoaded?: boolean
}>()

const emits = defineEmits(['click-categories'])
const router = useRouter()
const route = useRoute()
const imageStore = useImagesYarosStore()

const state = reactive({
  orgInfo: {} as Organization,
  showText: true,
  currentWidth: 0,
  isMobile: false,
  isFavEmpty: false,
  isOrdersNotEmpty: false,
  currentPath: '',
  restaurantName: '',
  dynamicFontSize: '',
  previousRoute: ''
})

const imageSrc = ref('')

onBeforeMount(async () => {
  state.currentPath = route.path
  state.showText = props.showText
  state.currentWidth = window.innerWidth
  state.orgInfo = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.restaurantName = state.orgInfo.name || ''
  updateDynamicFontSize()
})

async function toHomeOrPrevious () {
  const previousRoute = await router.currentRoute.value.meta.previousRoute
  if (previousRoute) {
    router.push(previousRoute)
  } else {
    router.push('/main')
  }
}

const loadImage = async () => {
  return await imageStore.getImage(state.orgInfo?.logo)
}

watch(() => state.orgInfo?.logo, (newValue) => {
  if (newValue.length > 0) {
    loadImage().then(result => { imageSrc.value = result || '' })
  }
})

watch(() => state.currentWidth, (newValue) => {
  if (newValue <= 786) {
    state.isMobile = true
  } else {
    state.isMobile = false
  }
})

watch(() => props.isFavEmpty, (newValue) => {
  state.isFavEmpty = newValue
})

async function toOrders () {
  await delay(100)
  router.push({ name: 'order' })
}

function toHome () {
  if (!props.showBackBtn) {
    router.push({ name: 'main' })
  }
}

function toFavorites () {
  router.push({ name: 'favorites' })
}

function updateDynamicFontSize () {
  const maxLength = 12
  const baseFontSize = 6

  state.dynamicFontSize = state.restaurantName.length > maxLength ? `${baseFontSize - 0.5 * (state.restaurantName.length - maxLength)}vw` : `${baseFontSize}vw`
}

eventBus.on('changed-store', () => {
  state.isFavEmpty = JSON.parse(sessionStorage.getItem('favorites') || '[]').length !== 0
  state.isOrdersNotEmpty = JSON.parse(sessionStorage.getItem('orders') || '[]').length !== 0
}
)
</script>

<style scoped>
.header-container, .icons-container {
  display: flex;
  align-items: center;
}

.header-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2999;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.2rem 2rem 3rem;
  /* background-color: #fff; */
  border-radius: 0 0 5rem 5rem;
}

.icons-container {
  gap: 0.1rem;
  cursor: pointer;
}

.restaurant-container {
  font: 2em "SP Pro Text Bold", sans-serif;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.restaurant-container span {
  width: 35vw;
}

.restaurant-container img {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.no-image-logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.no-image-logo img {
  max-width: 80%;
}

.no-image-style {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

.img-loading {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background-color: #dbdbdb;
  background-size: cover;
  overflow: hidden;
  animation: pulse 0.5s ease-in-out infinite alternate;
}

.text-loading {
  width: 10rem!important;
  height: 2rem;
  color: transparent;
  background-color: #dbdbdb;
  animation: pulse 0.5s ease-in-out infinite alternate;
  border-radius: 0.5rem;
}
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.cursor {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .header-container {
    padding: 5vw 7vw 5vw 7vw;
    border-radius: 0 0 8vw 8vw;
  }
  .header-container, .icons-container {
    display: flex;
    align-items: center;
  }
  .icons-container {
    gap: 1vw;
  }
  .restaurant-container {
    font-size: 6vw;
  }
  .header-container {
    margin-bottom: 5vw;
  }
  .restaurant-container img {
    width: 8vw;
    height: 8vw;
  }
  .restaurant-container span {
    width: 35vw;
  }
  .img-loading {
    width: 8vw;
    height: 8vw;
  }
  .text-loading {
    width: 15vw;
    height: 7vw;
    border-radius: 0.5rem;
  }
}
</style>
