<template>
 <div class="error-container">
  <div>
    <h2>404</h2>
    <p>Страница не найдена</p>
  </div>
 </div>
</template>
<script setup lang="ts">
</script>
<style scoped>
.error-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100vh;
}
.error-container div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.error-container h2 {
    font: 10rem 'Montserrat Bold', sans-serif;
    color: var(--main-color);
    margin: 0;
}
.error-container p {
    font: 2rem 'Montserrat Regular', sans-serif;
    color: var(--primary-font-color);
    margin: 0;
}
@media screen and (max-width: 768px) {
  .error-container h2 {
    font-size: 20vw;
  }
  .error-container p {
    font-size: 6vw;
  }
}
</style>
