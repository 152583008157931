export function rebuildString (string: string) {
  const result = string.replace(/^_+|_+$/g, '').replace(/_+/g, ' ')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function delay (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function roundPrice (price: string | number | undefined) {
  if (price) {
    const rounded = Math.round((price ? Number(price) : 0) * 100) / 100
    return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(2).toString()
  } else {
    return '0'
  }
}

export function rgbToHex (color: string): string | null {
  try {
    color = color.replace(/\s+/g, '')
    const parts = color.split(',').map(part => parseInt(part.trim(), 10))

    const toHex = (colorValue: number): string => {
      const hex = colorValue.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }

    return `#${toHex(parts[0])}${toHex(parts[1])}${toHex(parts[2])}`
  } catch {
    return null
  }
}

export function getRandomNumber (min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
