<template>
    <div class="miniature-container button-click" @click="catClicked">
        <div v-if="props.isLoaded" class="image-container">
            <img :src="noImage" v-if="!props.element.IMAGE_URL || props.element.IMAGE_URL === ''">
            <img :src="props.element.IMAGE_URL" v-else>
        </div>
        <div class="img-loading" v-else>
        </div>
        <div class="good-info-container">
            <span :class="[!props.isLoaded ? 'text-loading' : 'good-title']">{{ rebuildString(props.element.TITLE) }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Category } from '../../store/index'
import { rebuildString, delay } from '../../globalFunctions'
import noImage from '../../assets/images/no_image.svg'

const props = defineProps<{ element: Category, isLoaded?: boolean }>()

const emits = defineEmits(['choose-category'])

async function catClicked () {
  await delay(100)
  emits('choose-category', props.element)
}
</script>

<style scoped>
.img-loading {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  background-color: #dbdbdb;
  background-size: cover;
  margin-bottom: 0.6rem;
  animation: pulse 0.5s ease-in-out infinite alternate;
}

.text-loading {
  color: transparent;
  display: block;
  width: 5rem;
  height: 1rem;
  background-color: #dbdbdb;
  animation: pulse 0.5s ease-in-out infinite alternate;
  border-radius: 1rem;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.miniature-container {
    width: 5rem;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
.image-container {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
}
.image-container {
    max-width: 100%;
    margin-bottom: 0.6rem;
}
.good-info-container {
    text-align: center;
}
.good-title {
    font: 0.8rem 'Montserrat Medium', sans-serif;
    color: var(--primary-font-color);
}
@media screen and (max-width: 768px) {
    .image-container {
        width: 15vw;
        height: 15vw;
    }
    .img-loading {
        width: 15vw;
        height: 15vw;
    }
    .text-loading {
        width: 15vw;
    }
    .miniature-container {
        width: 15vw;
    }
}
</style>
