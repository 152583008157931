<template>
    <svg :width="state.screenWidth" :height="state.screenHeight" :viewBox="`0 0 ${state.screenWidth} ${state.screenHeight}`"
        xmlns="http://www.w3.org/2000/svg">
        <rect :x="state.rectCoords.x" :y="state.rectCoords.y" :width="state.rectWidth" height="50" fill="#F5A90D" />
        <ellipse :cx="state.leftCircleCoords.cx" :cy="state.leftCircleCoords.cy" :rx="state.leftCircleCoords.rx" ry="25"
            fill="#F5A90D" />
        <ellipse :cx="state.rigthCircleCoords.cx" :cy="state.rigthCircleCoords.cy" :rx="state.rigthCircleCoords.rx" ry="25"
            fill="#F5A90D" />
    </svg>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { delay } from '../../globalFunctions'

const radiusDefault = 25
const twoRem = parseFloat(getComputedStyle(document.documentElement).fontSize) * 2

const state = reactive({
  screenWidth: 400,
  maxBorder: 0,
  screenHeight: radiusDefault * 2,
  rectWidth: 0,
  startBorder: 0,
  rectCoords: { x: radiusDefault, y: 0 },
  leftCircleCoords: { cx: radiusDefault, cy: radiusDefault, rx: radiusDefault },
  rigthCircleCoords: { cx: radiusDefault, cy: radiusDefault, rx: radiusDefault }
})

const emits = defineEmits(['stoped'])

const props = defineProps({
  stop: {
    type: Boolean,
    required: true
  }
})

onBeforeMount(async () => {
  state.startBorder = twoRem
  if (window.innerWidth <= 768) {
    state.screenWidth = window.innerWidth - twoRem
  } else { state.screenWidth = 500 }
  state.maxBorder = state.screenWidth - twoRem
  state.leftCircleCoords.cx = twoRem
  state.rigthCircleCoords.cx = twoRem
  state.rectCoords.x = twoRem
  animationMain()
})

async function animationMain () {
  const pxDiff = state.screenWidth * 0.007
  const pxDiffPop = state.screenWidth * 0.02
  const delayBetweenStep = 700
  while (state.rigthCircleCoords.cx <= state.maxBorder) {
    state.rigthCircleCoords.cx += pxDiff
    state.rectWidth += pxDiff
    await delay(1)
  }
  state.rigthCircleCoords.cx = state.maxBorder
  state.rectWidth = state.maxBorder - twoRem
  await delay(delayBetweenStep)
  while (state.leftCircleCoords.cx <= state.maxBorder) {
    state.leftCircleCoords.cx += pxDiffPop
    if (state.rectWidth < pxDiffPop) {
      state.rectWidth = 0
    } else { state.rectWidth -= pxDiffPop }
    state.rectCoords.x += pxDiffPop
    await delay(1)
  }
  state.leftCircleCoords.cx = state.maxBorder
  state.rectWidth = 0
  await popCircle()
  await delay(delayBetweenStep - 100)
  while (state.rigthCircleCoords.cx >= twoRem) {
    state.rigthCircleCoords.cx -= pxDiffPop
    state.leftCircleCoords.cx -= pxDiffPop
    await delay(1)
  }
  state.rectCoords.x = twoRem
  state.rigthCircleCoords.cx = twoRem
  state.leftCircleCoords.cx = twoRem
  await popCircle()
  await delay(delayBetweenStep)
  if (!props.stop) {
    animationMain()
  } else {
    emits('stoped')
  }
}

async function popCircle () {
  const minRX = radiusDefault * 0.8
  const maxRX = radiusDefault
  while (state.leftCircleCoords.rx >= minRX) {
    state.leftCircleCoords.rx -= 1
    state.rigthCircleCoords.rx -= 1
    await delay(1)
  }
  while (state.leftCircleCoords.rx <= maxRX) {
    state.leftCircleCoords.rx += 1
    state.rigthCircleCoords.rx += 1
    await delay(1)
  }
}
</script>

<style></style>
