<template>
  <div class="loading-wrapper">
    <u-loading class="loading-animation" :stop="state.isLoaded" @stoped="nextPage"/>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Organization, useOrganizationStore } from '../store/organization'

const route = useRoute()
const router = useRouter()
const organization = useOrganizationStore()

const state = reactive({
  organization: {} as Organization | null | undefined,
  orgId: '',
  isLoaded: false,
  result: false
})

onBeforeMount(async () => {
  let orgId = route.params.orgId
  if (Array.isArray(orgId)) {
    orgId = orgId[0]
  }

  if (!orgId || orgId === '') {
    state.isLoaded = true
    return
  } else {
    state.orgId = orgId
  }

  await new Promise<void>(resolve => {
    sessionStorage.setItem('orgId', JSON.stringify(state.orgId))
    resolve()
  })

  await organization.getOrganizationAction(state.orgId)
  state.organization = organization.getOrganization
  if (!state.organization || Object.keys(state.organization).length === 0) {
    state.isLoaded = true
    return
  }

  await new Promise<void>(resolve => {
    sessionStorage.setItem('organization', JSON.stringify(state.organization))
    resolve()
  })

  state.isLoaded = true
  state.result = true
})

function nextPage () {
  if (state.result) {
    router.push({ name: 'main' })
  } else {
    router.push({ name: 'error' })
  }
}
</script>

<style>
.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
