<template>
    <div class="circle button-click">
        <img :style="{ width: props.iconWidth + props.iconUnit,
                       marginTop: props.iconMnTp + props.iconMnUnit,
                       marginRight: props.iconMnRt + props.iconMnUnit,
                       marginBottom: props.iconMnBt + props.iconMnUnit,
                       marginLeft: props.iconMnLt + props.iconMnUnit }"
             :src="state.icon" class="icon">
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, onBeforeMount } from 'vue'
import favIcon from '../../assets/icons/favorite.svg'
import activeFavIcon from '../../assets/icons/favorite-btn-active.svg'
import backIcon from '../../assets/icons/back.svg'
import catIcon from '../../assets/icons/categories.svg'
import pickIcon from '../../assets/icons/pick.svg'
import unpickIcon from '../../assets/icons/unpick.svg'
import { rgbToHex } from '../../globalFunctions'

const props = defineProps({
  type: {
    type: String,
    default: '',
    validator: (value: string) => ['favorite', 'back', 'category', 'pick'].includes(value)
  },
  isFav: {
    type: Boolean,
    default: false
  },
  iconWidth: {
    type: Number,
    default: 0
  },
  iconUnit: {
    type: String,
    default: ''
  },
  iconMnTp: {
    type: Number,
    default: 0
  },
  iconMnBt: {
    type: Number,
    default: 0
  },
  iconMnLt: {
    type: Number,
    default: 0
  },
  iconMnRt: {
    type: Number,
    default: 0
  },
  iconMnUnit: {
    type: String,
    default: 'rem'
  }
})

const iconsMap: Record<typeof props.type, string> = {
  favorite: props.isFav ? activeFavIcon : favIcon,
  back: backIcon,
  category: catIcon,
  pick: props.isFav ? pickIcon : unpickIcon
}

const state = reactive({
  icon: iconsMap[props.type] || '',
  activeColor: ''
})

const baseColor = process.env.VUE_APP_ACTIVE_COLOR

watch(() => props.isFav, () => {
  changeIcon()
})

onBeforeMount(async () => {
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== null ? activeColor : baseColor
  state.icon = await changeColor(null)
})

async function changeIcon () {
  if (props.type === 'favorite') {
    state.icon = props.isFav ? activeFavIcon : favIcon
    if (state.icon === activeFavIcon) {
      state.icon = await changeColor(state.icon)
    }
  } else if (props.type === 'pick') {
    state.icon = props.isFav ? pickIcon : unpickIcon
    if (state.icon === pickIcon) {
      state.icon = await changeColor(state.icon)
    }
  }
}

async function changeColor (icon: string | undefined | null): Promise<string> {
  icon = icon || icon === '' ? icon : state.icon
  if (!props.isFav) {
    return icon
  }
  return fetch(icon)
    .then(response => response.text())
    .then(svgContent => {
      const parser = new DOMParser()
      const svgDom = parser.parseFromString(svgContent, 'image/svg+xml')
      let path = svgDom.querySelector('g')?.querySelector('path')
      if (!path) {
        path = svgDom.querySelector('svg')?.querySelector('path')
      }

      if (path) {
        path.setAttribute('fill', state.activeColor)
      }

      const serializer = new XMLSerializer()
      const updatedSvgContent = serializer.serializeToString(svgDom.documentElement)
      return `data:image/svg+xml,${encodeURIComponent(updatedSvgContent)}`
    })
}
</script>

<style scoped>
.circle {
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
}

.icon {
  /* width: 1.7rem; */
  height: auto;
  /* margin-top: 0.2rem; */
}
@media screen and (max-width: 768px) {
  .circle {
    padding: 0.1rem;
  }
}
</style>
