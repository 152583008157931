import { defineStore } from 'pinia'
import axios, { AxiosError } from 'axios'

interface OrgPaln {
    name: string
    planId: string
    numOfPer: number
    price: number
    fromDate: number
}

export interface Organization {
    orgId: string
    name: string
    phoneNumber: string
    email: string
    logo: string
    chatId: string
    timezone: number
    timeReport: number
    firebaseAuthToken: string
    balance: number
    plan: OrgPaln
    paidUntil: number
    paidDays: number
    bonusLifeTime: number
    bonusPayLimit: number
    colorOfActivity: string
}

export const useOrganizationStore = defineStore('org', {
  state: () => ({
    organization: {} as Organization | undefined | null
  }),
  actions: {
    async getOrganizationAction (orgId: string) {
      try {
        const response = await axios.get<Organization>(`/org/${orgId}`)
        if (response.data && Object.keys(response.data).length > 0) {
          this.organization = response.data
        } else {
          this.organization = null
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  },
  getters: {
    getOrganization: (state) => {
      return state.organization
    }
  }
})
