import { reactive, watchEffect, WatchStopHandle } from 'vue'

type EventCallback = () => void

class EventBus {
  private state = reactive({ emitName: '' })

  emit (event: string) {
    this.state.emitName = ''
    this.state.emitName = event
  }

  on (event: string, callback: EventCallback): WatchStopHandle {
    return watchEffect(() => {
      if (this.state.emitName !== '' && this.state.emitName === event) {
        callback()
      }
    })
  }
}
export const eventBus = new EventBus()
