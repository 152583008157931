<template>
    <div class="overlay">
        <div class="dialog" ref="dialog">
        <span class="header-text">Категории</span>
        <div class="line"></div>
            <div ref="catSelectorBody" class="cat-selector-body">
                <div v-for="category in props.categories" :key="category.ID" class="cat-selector-block">
                <span @click="catClicked(category.ID)" :class="{ active: state.activeCategoryId === category.ID }" class="category-list">
                    {{ rebuildString(category.TITLE) }}
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, reactive } from 'vue'
import { Category } from '../../store/index'
import { rebuildString, delay } from '../../globalFunctions'

const props = defineProps<{ categories: Category[] }>()
const emits = defineEmits(['click-category'])

const state = reactive({
  activeCategoryId: ''
})

const catSelectorBody = ref<HTMLElement | null>(null)
const dialog = ref<HTMLElement | null>(null)

async function catClicked (categoryId: string) {
  state.activeCategoryId = categoryId
  await delay(100)
  emits('click-category', categoryId)
}

onBeforeMount(() => {
  const container = catSelectorBody.value
  if (container) {
    let isDragging = false
    let startY = 0
    let scrollTop = 0

    container.addEventListener('mousedown', (e) => {
      isDragging = true
      startY = e.pageY - container.offsetTop
      scrollTop = container.scrollTop
    })

    container.addEventListener('mouseleave', () => {
      isDragging = false
    })

    container.addEventListener('mouseup', () => {
      isDragging = false
    })

    container.addEventListener('mousemove', (e) => {
      if (!isDragging) return
      e.preventDefault()
      const y = e.pageY - container.offsetTop
      const walk = y - startY
      container.scrollTop = scrollTop - walk
    })
  }
})

</script>

<style>
.dialog {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  padding: 2.5rem 0 2.5rem 3.125rem;
  border-radius: 0 2.5rem 2.5rem 0;
  display: flex;
  flex-direction: column;
  width: 25rem;
  height: auto;
}

.line {
  background-color: #cccccc;
  height: 2px;
  width: 100%;
  margin: 1.625rem 0 1.625rem 0;
}

.header-text {
  font: 1.43rem 'Montserrat SemiBold', sans-serif;
  color: var(--primary-font-color);
}

.category-list {
  font: 1.25rem 'Montserrat Medium', sans-serif;
  color: var(--primary-font-color);
  cursor: pointer;
  user-select: none;
}

.cat-selector-body {
  overflow-y: scroll;
  scrollbar-width: none;
  height: 90vh;
}

.cat-selector-body::-webkit-scrollbar {
  display: none;
}

.cat-selector-body {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.20rem;
}

.active {
  color: #f5a90d;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 3000;
}
@media screen and (max-width: 768px) {
  .dialog {
    width: 65vw;
    padding: 8vw 0vw 7vw 7vw;
  }
  .header-text {
    font-size: 5vw;
  }
  .category-list {
    font-size: 4.5vw;
  }
  .cat-selector-body {
    gap: 4vw;
  }
}
</style>
