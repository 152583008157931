<template>
  <div></div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue'

const emits = defineEmits(['is-mobile', 'org-info'])

onBeforeMount(async () => {
  emits('is-mobile', window.innerWidth <= 768)
})
</script>

<style>

</style>
