<template>
    <transition name="udialog-fade">
      <div class="udialog-overlay">
        <div class="udialog">
          <span class="udialog-header">{{ props.header }}</span>
          <div class="udialog-body" v-html="props.msgText"></div>
          <div class="udialog-buttons" v-if="props.showButtons || props.showButtons !== false">
            <button @click="emits('click-res', 'ok')" class="ures-btn button-click">Ок</button>
            <button v-if="props.cancelButton" @click="emits('click-res', 'cancel')" class="ures-btn button-click">Отмена</button>
          </div>
        </div>
      </div>
    </transition>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { rgbToHex } from '../../globalFunctions'

const baseColor = process.env.VUE_APP_ACTIVE_COLOR

const state = reactive({
  activeColor: baseColor
})

const props = defineProps({
  header: {
    type: String,
    default: '',
    required: true
  },
  msgText: {
    type: String,
    default: ''
  },
  cancelButton: {
    type: Boolean,
    default: false
  },
  showButtons: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['click-res'])

onBeforeMount(() => {
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== null ? activeColor : baseColor
  document.documentElement.style.setProperty('--your-active-color-variable', state.activeColor)
})

</script>

<style scoped>
.udialog-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.udialog {
  width: 45rem;
  background-color: white;
  padding: 20px;
  border-radius: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.udialog-buttons {
  display: flex;
  margin-top: 5vh;
  align-items: center;
  gap: 2vh;
}

.ures-btn {
  width: 20rem;
  font-family: 'Montserrat SemiBold', sans-serif;
  font-size: 1.5em;
  color: #fff;
  background-color: var(--your-active-color-variable);
  padding: 1.5rem 0.5rem;
  border-radius: 5vw;
  border: none;
}

.udialog-header {
  font-family: 'Montserrat SemiBold', sans-serif;
  font-size: 2em;
  margin-bottom: 1rem;
}

.udialog-body {
  font-family: 'Montserrat Regular', sans-serif;
  font-size: 1.5em;
}

@media screen and (max-width: 768px) {
  .udialog {
  width: 75vw;
  background-color: white;
  padding: 20px;
  border-radius: 8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.udialog-buttons {
  display: flex;
  margin-top: 5vh;
  align-items: center;
  gap: 2vh;
}

.ures-btn {
  min-width: 30vw;
  font-family: 'Montserrat SemiBold', sans-serif;
  font-size: 0.9em;
  color: #fff;
  background-color: var(--your-active-color-variable);
  padding: 2vw 3vw;
  border-radius: 5vw;
  width: fit-content;
  border: none;
}

.udialog-header {
  font-family: 'Montserrat SemiBold', sans-serif;
  font-size: 1em;
  margin-bottom: 2vh;
}

.udialog-body {
  font-family: 'Montserrat Regular', sans-serif;
  font-size: 0.8em;
}
}
</style>
