import { defineStore } from 'pinia'
import axios, { AxiosError } from 'axios'
import { getRandomNumber } from '../globalFunctions'

export interface Category {
  PARENT_ID: string
  ID: string
  TITLE: string
  SORT_PRIORITY: number
  IMAGE_URL: string
}

interface CategoriesResponse {
  status: string
  categories: Category[]
}

export interface Goods {
  ID: string
  CATEGORY_ID: string
  TITLE: string
  PRICE: number | string
  QUANTITY: number
  IMAGE_URL: string
  DESCRIPTION: string
  MEASURE: string
  SORT_PRIORITY: number
}

export const goodNotLoaded: Goods = { ID: '1', CATEGORY_ID: '', TITLE: ' '.repeat(getRandomNumber(5, 20)), PRICE: '   ', QUANTITY: 0, IMAGE_URL: '', DESCRIPTION: '', MEASURE: '', SORT_PRIORITY: 0 }
export const catNotLoaded: Category = { PARENT_ID: '', ID: '', TITLE: ' '.repeat(getRandomNumber(5, 20)), SORT_PRIORITY: 0, IMAGE_URL: '' }

interface GoodsResponse {
  status: string
  goods: Goods[]
}

interface GoodResponse {
  status: string
  goods: Goods[]
}

export interface StopList {
  ID: string
}

interface StopListResponse {
  status: string
  stopList: StopList[]
}

export const useStore = defineStore('store', {
  state: () => ({
    categories: [] as Category[],
    goods: [] as Goods[],
    stopList: [] as StopList[],
    currentItem: null as Goods | null,
    good: null as Goods | null
  }),
  actions: {
    async getCategoriesAction (orgId: string) {
      try {
        const response = await axios.get<CategoriesResponse>(`/categories/${orgId}`)
        if (response.data && response.data.status === 'success') {
          this.categories = response.data.categories
        } else {
          this.categories = []
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async getGoodsAction (orgId: string) {
      try {
        const response = await axios.get<GoodsResponse>(`/goods/${orgId}`)
        if (response.data && response.data.status === 'success') {
          this.goods = response.data.goods
        } else {
          this.goods = []
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async getStopListAction (orgId: string) {
      try {
        const response = await axios.get<StopListResponse>(`/stopList/${orgId}`)
        if (response.data && response.data.status === 'success') {
          this.stopList = response.data.stopList
        } else {
          this.stopList = []
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async getGoodAction (orgId: string, ID: string) {
      try {
        const response = await axios.get<GoodResponse>(`/prodID/${orgId}`, {
          params: {
            good_id: ID
          }
        }
        )
        if (response.data && response.data.status === 'success') {
          this.good = response.data.goods[0]
        } else {
          this.good = null
        }
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    },
    async setItem (item: Goods) {
      this.currentItem = item
    }
  },
  getters: {
    getCategories: (state) => {
      return state.categories
    },
    getGoods: (state) => {
      return state.goods
    },
    getStopList: (state) => {
      return state.stopList
    },
    getCurrentItem: (state) => {
      return state.currentItem
    },
    getGood: (state) => {
      return state.good
    }
  }
})
