<template>
  <div class="icon-container button-click">
    <img :style="{
      width: props.iconWidth + props.iconUnit,
      paddingTop: props.iconPdTp + props.iconPdUnit,
      paddingRight: props.iconPdRt + props.iconPdUnit,
      paddingBottom: props.iconPdBt + props.iconPdUnit,
      paddingLeft: props.iconPdLt + props.iconPdUnit
    }" :src="state.iconRef">
    <span v-if="props.showText"> {{ state.text }} </span>
  </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import categoriesIcon from '../../assets/icons/categories.svg'
import favoritesIcon from '../../assets/icons/favorite.svg'
import ordersIcon from '../../assets/icons/orders.svg'
import favoritesNotEmptyIcon from '../../assets/icons/favorite-not-empty.svg'
import ordersNotEmptyIcon from '../../assets/icons/orders_not_empty.svg'
import { rgbToHex } from '../../globalFunctions'

const props = defineProps({
  type: String,
  showText: {
    type: Boolean,
    default: false,
    required: false
  },
  iconWidth: {
    type: Number,
    default: 0,
    required: false
  },
  iconUnit: {
    type: String,
    default: 'rem',
    required: false
  },
  isEmpty: {
    type: Boolean,
    default: true,
    required: false
  },
  iconPdTp: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdRt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdBt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdLt: {
    type: Number,
    default: 0,
    required: false
  },
  iconPdUnit: {
    type: String,
    default: 'rem',
    required: false
  }
})

const state = reactive({
  iconRef: '',
  text: '',
  activeColor: ''
})

const iconMap = {
  categories: { text: 'Категории', iconRef: categoriesIcon },
  favorite: { text: 'Избранное', iconRef: !props.isEmpty ? favoritesIcon : favoritesNotEmptyIcon },
  orders: { text: 'Заказ', iconRef: !props.isEmpty ? ordersIcon : ordersNotEmptyIcon }
}

const baseColor = process.env.VUE_APP_ACTIVE_COLOR

onBeforeMount(async () => {
  const icon = iconMap[props.type as keyof typeof iconMap]
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== null ? activeColor : baseColor
  if (icon) {
    state.text = icon.text
    state.iconRef = icon.iconRef
    if (state.iconRef === favoritesNotEmptyIcon || state.iconRef === ordersNotEmptyIcon) {
      state.iconRef = await changeColor(icon.iconRef)
    }
  }
})

async function changeColor (icon: string): Promise<string> {
  return fetch(icon)
    .then(response => response.text())
    .then(svgContent => {
      const parser = new DOMParser()
      const svgDom = parser.parseFromString(svgContent, 'image/svg+xml')

      const ellipse = svgDom.querySelector('ellipse')

      if (ellipse) {
        ellipse.setAttribute('fill', state.activeColor)
      }

      const serializer = new XMLSerializer()
      const updatedSvgContent = serializer.serializeToString(svgDom.documentElement)
      return `data:image/svg+xml,${encodeURIComponent(updatedSvgContent)}`
    })
}

</script>

<style scoped>
  .icon-container{
    display: flex;
    flex-flow: row nowrap;
    /* font: 30px "SP Pro Text Medium", sans-serif; */
    /* font-weight: 500; */
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 3rem;
    height: 3rem;
  }
  .icon-container img {
    width: max-content;
  }

  @media screen and (max-width: 768px) {
    .icon-container {
      width: 9vw;
      height: 9vw;
    }
  }
</style>
