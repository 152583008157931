import { App } from 'vue'
import UCategory from './UCategory.vue'
import UHeader from './UHeader.vue'
import UIcon from './UIcon.vue'
import UGoodMiniature from './UGoodMiniature.vue'
import UCategoryMiniature from './UCategoryMiniature.vue'
import UCategorySelector from './UCategorySelector.vue'
import UIconRounded from './UIconRounded.vue'
import UCounter from './UCounter.vue'
import UDialog from './UDialog.vue'
import UListner from './UListner.vue'

// UI
import UButton from '../../components/UI/UButton.vue'
import ULoading from '../../components/UI/ULoadingAnimation.vue'

const components = [
  {
    name: 'u-category',
    component: UCategory
  },
  {
    name: 'u-header',
    component: UHeader
  },
  {
    name: 'u-icon',
    component: UIcon
  },
  {
    name: 'u-good-miniature',
    component: UGoodMiniature
  },
  {
    name: 'u-category-miniature',
    component: UCategoryMiniature
  },
  {
    name: 'u-category-selector',
    component: UCategorySelector
  },
  {
    name: 'u-icon-rounded',
    component: UIconRounded
  },
  {
    name: 'u-counter',
    component: UCounter
  },
  {
    name: 'u-button',
    component: UButton
  },
  {
    name: 'u-dialog',
    component: UDialog
  },
  {
    name: 'u-listner',
    component: UListner
  },
  {
    name: 'u-loading',
    component: ULoading
  }
]

export function registerGlobalComponents (app: App) {
  components.forEach(({ name, component }) => {
    app.component(name, component)
  })
}
