<template>
  <div class="main-container">
    <div>
      <u-header :isLoaded="true" :showLogo="true" :showRestaurantName="true"/>
    <div class="main-content-section goods-container">
        <div class="fav-func-panel">
            <h2 class="header-text">Избранное</h2>
            <div class="fav-func-panel-actions">
                <span class="button-click" @click="unpickChoosen"
                  :class="{ unactive: state.choosenIsNotEmpty === 'empty' }">
                    Снять выделенное
                </span>
                <span class="button-click" @click="chooseAll"
                    :class="{ unactive: state.choosenIsNotEmpty === 'full' }">
                    Выбрать все
                </span>
            </div>
        </div>
      <MainSwiper v-if="state.favorites.length > 0" :type="'favorites'" :catId="('ID' in state.category ? state.category.ID : '')"
        ref="swiper" @choosen-full-empty="choosenFullEmpty"/>
      <div v-else class="empty-favorites">
        <span>В избранном пока нет блюд...</span>
      </div>
      </div>
    </div>
    <div class="fav-btns">
      <u-button v-if="state.choosenIsNotEmpty !== 'empty'" :btnText="'Перейти к заказу'" class="fav-to-orders" @click="toOrder" />
      <u-button v-if="state.choosenIsNotEmpty === 'empty'" :btnText="'Вернуться на главную'" class="fav-to-orders" @click="toMain" />
    </div>
</div>
</template>

<script setup lang="ts">
import { reactive, ref, type ComponentPublicInstance, onBeforeMount } from 'vue'
import { Goods, Category } from '../store/index'
import { useRouter } from 'vue-router'
import { rgbToHex } from '../globalFunctions'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MainSwiper from './MainSwiper.vue'

interface MainSwiperMethods {
  cleanChoosen: () => void,
  chooseAll: () => void,
  toOrder: () => void
}

const swiper = ref<ComponentPublicInstance<object, MainSwiperMethods> | null>(null)
const router = useRouter()
const baseColor = process.env.VUE_APP_ACTIVE_COLOR

const state = reactive({
  category: {} as Category | object,
  favorites: [] as Array<Goods>,
  choosenIsNotEmpty: '',
  activeColor: baseColor
})

onBeforeMount(async () => {
  state.favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]')
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== null ? activeColor : baseColor
  document.documentElement.style.setProperty('--your-active-color-variable', state.activeColor)
})

function unpickChoosen () {
  swiper.value?.cleanChoosen()
}

function chooseAll () {
  swiper.value?.chooseAll()
}

function toOrder () {
  swiper.value?.toOrder()
}

function toMain () {
  router.push({ name: 'main' })
}

function choosenFullEmpty (arg: string) {
  state.choosenIsNotEmpty = arg
}

</script>

<style scoped>
.empty-favorites {
  font: 1.5rem 'Montserrat Regular', sans-serif;
  color: var(--side-font-color);
  margin-top: 1rem;
}
.main-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 79vh;
  margin: 6rem 3rem 3rem 3rem;
}
.fav-func-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fav-func-panel-actions {
    display: flex;
    gap: 2vw;
    font: 1rem 'Montserrat SemiBold', sans-serif;
    color: var(--your-active-color-variable);
}
.unactive {
    color: #AAAAAA;
}
.fav-btn {
  margin: 2.5rem 0;
}
.fav-to-orders {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.button-click {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .empty-favorites {
    font-size: 3.5vw;
    margin-top: 1vw;
  }
  .main-container {
    height: 86vh;
    margin: 18vw 7vw 7vw 7vw;
  }
  .fav-func-panel {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  .fav-func-panel-actions {
    font-size: 3vw;
    margin-bottom: 5vw;
  }
  .header-text {
    margin-bottom: 2vw;
  }
  .favorites-wrapper {
    margin-bottom: 5vw;
  }
  .fav-btns {
    margin: 5vw 0;
  }
  .fav-to-orders {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
}
</style>
