<template>
    <div :class="[ state.currentPath.includes('item') ? 'good-counter-wrapper' : 'counter-wrapper']">
        <div :class="[ state.currentPath.includes('item') ? 'good-counter-act' : 'counter-act-container' ]" @click.stop="counterClick('del')">
          <img src="@/assets/icons/dec.svg" >
        </div>
        <div :class="[ state.currentPath.includes('item') ? 'good-space' : 'space' ]">
            <span>{{ state.counter }}</span>
        </div>
        <div :class="[ state.currentPath.includes('item') ? 'good-counter-act' : 'counter-act-container' ]" @click.stop="counterClick('add')">
          <img src="@/assets/icons/inc.svg">
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

onBeforeMount(async () => {
  state.currentPath = route.path
})

export interface CounterResult {
  id: string,
  price: number;
  counter: number;
}

const props = defineProps({
  counter: {
    type: Number,
    default: 0
  },
  price: {
    type: Number,
    required: true
  },
  maxQty: {
    type: Number,
    default: null
  },
  id: {
    type: String,
    required: true
  }
})

const emits = defineEmits<{(event: 'ucounter-click', payload: CounterResult): void}>()
const route = useRoute()

const state = reactive({
  counter: props.counter,
  price: props.price * props.counter,
  maxQty: props.maxQty as number | null,
  currentPath: ''
})

function counterClick (arg: 'add' | 'del') {
  if (arg === 'del') {
    if (state.counter === 0) {
      return
    }
    state.counter--
  } else if (arg === 'add' && (!state.maxQty || state.counter < state.maxQty)) {
    state.counter++
  }
  state.price = props.price * state.counter

  emits('ucounter-click', {
    id: props.id,
    counter: state.counter,
    price: state.price
  })
}

watch(
  () => props.counter,
  (newValue) => {
    state.counter = newValue
    state.price = props.price * newValue
  }
)
</script>

<style>
.counter-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5vw;
}

.counter-wrapper img {
  width: 0.9rem;
}

.counter-act-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

.space {
    font: 1.3rem 'Montserrat Bold', sans-serif;
    color: var(--primary-font-color);
    display: flex;
    justify-content: center;
    width: 2rem;
}

.good-counter-wrapper {
    display: flex;
    align-items: center;
    gap: 1vw;
}

.good-counter-wrapper img {
  width: 1.5rem;
}

.good-counter-act {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 1.1rem;
  width: 1.1rem;
}

.good-space {
    font: 1.9rem 'Montserrat Bold', sans-serif;
    color: var(--primary-font-color);
    display: flex;
    justify-content: center;
    width: 2rem;
}

@media screen and (max-width: 768px) {
  .space {
    font-size: 5vw;
    width: 6vw;
  }
  .counter-wrapper img {
    width: 3.5vw;
  }
  .counter-wrapper {
    gap: 1.5vw;
  }
  .good-space {
    font-size: 5.3vw;
    width: 6vw;
  }
  .good-counter-wrapper {
    gap: 1.5vw;
  }
  .good-counter-wrapper img {
    width: 3.5vw;
  }
}
</style>
