<template>
  <div ref="mainContainer">
    <div :class="[props.type === 'goods' || props.type === 'favorites' ? 'main-swiper-container-goods' : 'main-swiper-container']" v-if="state.isLoaded">
      <div v-for="element in state.array" :key="element.ID">
        <u-good-miniature :isStop="state.isStop" :isLoaded="state.isLoaded" :element="element" v-if="props.type === 'goods' || props.type === 'favorites'"
          @red-favorites="redStore" @red-choosen="redChoosen"
          :isFav="state.favorites.some(favoriteItem => favoriteItem.ID === element.ID)"
          :choosen="state.choosen.some(choosenItem => choosenItem.ID === element.ID)" :pickIsNeed="props.type === 'favorites'"/>
        <u-category-miniature :isLoaded="state.isLoaded" :element="element" v-if="props.type === 'categories'" @choose-category="chooseCat(element.ID)"/>
      </div>
    </div>
    <div :class="[props.type === 'goods' || props.type === 'favorites' ? 'main-swiper-container-goods' : 'main-swiper-container']" v-else>
      <div v-for="i in 10" :key="i">
        <u-good-miniature
          :element="goodNotLoaded"
          v-if="props.type === 'goods' || props.type === 'favorites'"
          />
        <u-category-miniature
        :element="catNotLoaded"
        v-if="props.type === 'categories'"
        />
      </div>
    </div>
  </div>
  <u-listner @is-mobile="isMobile" />
  <u-dialog v-if="state.dialogShow" :header="state.dialogHeader" :msgText="state.dialogText" :cancelButton="true" @click-res="dialogResult"/>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore, Goods, Category, goodNotLoaded, catNotLoaded } from '../store/index'
import { RedStoreArg } from '../views/global/UGoodMiniature.vue'
import { roundPrice } from '../globalFunctions'
import { OrderItem } from '../views/ItemView.vue'
import { eventBus } from '../eventBus'

const store = useStore()

defineExpose({ cleanChoosen, chooseAll, toOrder })

const props = defineProps({ type: String, catId: String })

const router = useRouter()

const emits = defineEmits(['categories-loaded', 'choose-category', 'choosen-full-empty', 'fav-empty'])

const state = reactive({
  orgId: '',
  array: [] as Array<Goods | Category>,
  goods: [] as Array<Goods>,
  isMobile: false,
  favorites: [] as Array<Goods>,
  orders: [] as Array<Goods>,
  choosen: [] as Array<Goods>,
  dialogHeader: 'Добавить к заказу?',
  dialogText: '',
  dialogShow: false,
  isLoaded: false,
  isStop: false,
  isGoodsLoaded: false
})

const mainContainer = ref<HTMLElement | null>(null)

onBeforeMount(async () => {
  state.orgId = await JSON.parse(sessionStorage.getItem('orgId') || '""')
  state.favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]')
  state.orders = JSON.parse(sessionStorage.getItem('orders') || '[]')
  eventBus.emit('changed-store')

  if (props.type === 'goods') {
    await store.getGoodsAction(state.orgId)
    state.goods = store.getGoods
    state.array = state.goods.sort((a, b) => a.SORT_PRIORITY - b.SORT_PRIORITY)
    state.isLoaded = true
  } else if (props.type === 'categories') {
    await store.getCategoriesAction(state.orgId)
    state.array = store.getCategories.sort((a, b) => a.SORT_PRIORITY - b.SORT_PRIORITY)
    state.isLoaded = true
    emits('categories-loaded', state.array)
  } else if (props.type === 'favorites') {
    state.array = state.favorites
    state.choosen = JSON.parse(JSON.stringify(state.favorites))
    state.isLoaded = true
  }
})

onMounted(() => {
  const container = mainContainer.value
  if (container) {
    let isDragging = false
    let startX = 0
    let scrollLeft = 0

    container.addEventListener('mousedown', (e) => {
      isDragging = true
      startX = e.pageX - container.offsetLeft
      scrollLeft = container.scrollLeft
    })

    container.addEventListener('mouseleave', () => {
      isDragging = false
    })

    container.addEventListener('mouseup', () => {
      isDragging = false
    })

    container.addEventListener('mousemove', (e) => {
      if (!isDragging) return
      e.preventDefault()
      const x = e.pageX - container.offsetLeft
      const walk = x - startX
      container.scrollLeft = scrollLeft - walk
    })
  }
})

watch(() => props.catId, (category) => {
  if (category === '') {
    state.array = state.goods
  } else {
    state.array = state.goods.filter(item => item.CATEGORY_ID === category)
  }
})

watch(() => state.choosen, (choosen) => {
  const choosenLength = choosen.length
  if (choosenLength === 0) {
    emits('choosen-full-empty', 'empty')
  } else if (choosenLength === state.favorites.length) {
    emits('choosen-full-empty', 'full')
  } else {
    emits('choosen-full-empty', 'not-empty')
  }
})

function chooseCat (catId: string) {
  emits('choose-category', catId)
}

function isMobile (arg: boolean) {
  state.isMobile = arg
}

async function redStore (arg: RedStoreArg) {
  const arr: Array<Goods> = JSON.parse(sessionStorage.getItem(arg.store) || '[]')
  const index = arr.findIndex(item => item.ID === arg.good.ID)
  if (index !== -1) {
    arr.splice(index, 1)
  } else {
    arr.push(arg.good)
  }
  if (arg.store === 'favorites') {
    state.favorites = arr
  }
  if (props.type === 'favorites') {
    state.array = arr
  }
  if (state.choosen && state.choosen.length > 0) {
    redChoosen(arg)
  }
  sessionStorage.setItem(arg.store, JSON.stringify(arr))
  if (state.favorites.length === 0) {
    emits('fav-empty', true)
  } else {
    emits('fav-empty', false)
  }
  eventBus.emit('changed-store')
}

function redChoosen (arg: RedStoreArg) {
  const index = state.choosen.findIndex(item => item.ID === arg.good.ID)
  if (index !== -1) {
    state.choosen.splice(index, 1)
  } else {
    state.choosen.push(arg.good)
  }
  state.choosen = [...state.choosen]
}

function cleanChoosen () {
  state.choosen = []
}

function chooseAll () {
  state.choosen = JSON.parse(JSON.stringify(state.favorites))
}

function toOrder () {
  state.dialogText = ''
  if (state.choosen.length > 0) {
    let totalPrice = 0
    for (const good of state.choosen) {
      state.dialogText += (good ? good.TITLE.replace(/^[._]+/, '') : '') + '<br>'
      totalPrice += Number(good.PRICE)
    }
    state.dialogText += `<br><b>На общую сумму: ${roundPrice(totalPrice)}c<b>`
    openDialog()
  }
}

function openDialog () {
  state.dialogShow = true
}

function dialogResult (arg: string) {
  if (arg === 'cancel') {
    state.dialogShow = false
    return
  }
  const orders = JSON.parse(sessionStorage.getItem('orders') || '[]')
  for (const good of state.choosen) {
    const goodIndex = orders.findIndex((order: OrderItem) => order.item.ID === good.ID)
    if (goodIndex !== -1) {
      orders[goodIndex].count += 1
    } else {
      orders.push({ item: good, count: 1 })
    }
  }
  sessionStorage.setItem('orders', JSON.stringify(orders))
  router.push({ name: 'order' })
}

</script>

<style>
.main-swiper-container {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: pointer;
  gap: 4.5rem;
}

.main-swiper-container::-webkit-scrollbar {
  display: none;
}

.main-swiper-container-goods {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, 1fr);
  gap: calc((15vw / 5));
  /* width: 100%; */
  justify-items: stretch;
  align-items: stretch;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .main-swiper-container-goods {
    grid-template-columns: repeat(4, 1fr);
    gap: calc((18vw / 4));
  }
}
@media screen and (max-width: 1024px) {
  .main-swiper-container-goods {
    grid-template-columns: repeat(4, 1fr);
    gap: calc((18vw / 4));
  }
}
@media screen and (max-width: 786px) {
  .main-swiper-container-goods {
    grid-template-columns: repeat(2, 1fr);
    gap: calc((18vw / 4));
  }
  .main-swiper-container {
    gap: 9.5vw;
  }
}
</style>
