import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainView from '../views/MainView.vue'
import ItemView from '../views/ItemView.vue'
import Order from '../views/OrderView.vue'
import Favorites from '../views/FavoritesView.vue'
import Error from '../views/ErrorView.vue'
import LoadingPage from '@/views/LoadingPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/main',
    name: 'main',
    component: MainView,
    meta: { previousRoute: null }
  },
  {
    path: '/item/:id',
    name: 'item',
    component: ItemView,
    props: true,
    meta: { previousRoute: null }
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    meta: { previousRoute: null }
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: Favorites,
    meta: { previousRoute: null }
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: { previousRoute: null }
  },
  {
    path: '/:orgId?',
    name: 'loading',
    component: LoadingPage,
    meta: { previousRoute: null }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  to.meta.previousRoute = from.fullPath
  try {
    const isConnected = navigator.onLine
    if (!isConnected && to.name !== 'error') {
      next({ name: 'error' })
    } else if (to.matched.length === 0) {
      next({ name: 'error' })
    } else {
      next()
    }
  } catch (error) {
    next({ name: 'error' })
  }
})

export default router
