<template>
  <div>
    <img src="">
    <span>{{ props.category.TITLE.replace(/_/g, ' ') }}</span>
  </div>
</template>

<script setup lang='ts'>
import { Category } from '@/store'

const props = defineProps<{ category: Category }>()
</script>

<style>

</style>
