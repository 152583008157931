<template>
  <div class="miniature-container">
    <div class="cursor" @click="toItem(props.element)">
      <div :class="[!imageSrc || imageSrc === '' && props.isLoaded ? 'no-image-style' : '', !props.isLoaded ? 'loading-image' : 'image-container']">
        <div v-if="props.isLoaded" class="button-click" :class="[props.isStop ? 'stop-style' : '']">
          <span v-if="props.isStop" class="stop-bg"><span>Блюдо на стопе</span></span>
          <img class="no-image" :src="noImage" v-if="!imageSrc || imageSrc === ''">
          <img class="good-img" :src="`data:image/jpeg;base64, ${imageSrc}`" v-else>
        </div>
        <div class="img-loading" v-else>
        </div>
      </div>
      <div v-if="props.isLoaded" class="good-btns">
        <u-icon-rounded v-if="!state.currentPath.includes('/order')" :type="'favorite'" :isFav="props.isFav" class="miniature-icon" :iconWidth="1.2" :iconUnit="'rem'"
          :iconMnTp="0.25" :iconMnLt="0.010" :iconMnUnit="'rem'" @click.stop="redFav(props.element)"/>
        <u-icon-rounded v-if="state.currentPath.includes('/order') || state.currentPath.includes('/favorites')" :type="'pick'" :isFav="props.choosen" class="miniature-icon" :iconWidth="1.2" :iconUnit="'rem'" :iconMnTp="0.11" :iconMnUnit="'rem'"
          @click.stop="redChoosen(props.element)"/>
      </div>
        <div class="good-info-container">
          <span :class="[!props.isLoaded ? 'text-loading' : 'good-title']">{{ props.element.TITLE.replace(/^[\._]+/, '') }}</span>
        </div>
      </div>
      <div class="good-price-counter">
        <span :class="[!props.isLoaded ? 'text-loading-price' : 'good-price']">{{ state.totalPrice }}с.</span>
        <u-counter v-if="props.showCounter && props.isLoaded" :id="props.element.ID" :price="Number(state.price)" :counter="props.count" @ucounter-click="countPrice"/>
      </div>
  </div>
</template>

<script setup lang="ts">
import { Goods, useStore } from '../../store/index'
import { onBeforeMount, reactive, PropType, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { CounterResult } from './UCounter.vue'
import noImage from '../../assets/images/no_image.svg'
import { useImagesYarosStore } from '@/store/images'

export interface RedStoreArg {
  store: string;
  good: Goods;
}

const imagesStore = useImagesYarosStore()
const imageSrc = ref('')

const props = defineProps({
  element: {
    type: Object as PropType<Goods>,
    required: true
  },
  isFav: Boolean,
  choosen: {
    type: Boolean,
    default: true
  },
  showCounter: Boolean,
  count: {
    type: Number,
    default: 0
  },
  pickIsNeed: {
    type: Boolean,
    default: false,
    required: false
  },
  isLoaded: {
    type: Boolean,
    default: false,
    required: false
  },
  isStop: {
    type: Boolean,
    default: false,
    required: false
  }
})

const router = useRouter()
const route = useRoute()
const emits = defineEmits <{(event: 'red-favorites', arg: RedStoreArg): void,
  (event: 'calc-total-price', total: CounterResult): void,
  (event: 'red-choosen', arg: RedStoreArg): void
}>()

const state = reactive({
  price: '',
  totalPrice: '',
  currentPath: ''
})

const store = useStore()

onBeforeMount(() => {
  state.currentPath = route.path
  const rounded = Math.round(Number(props.element.PRICE) * 100) / 100
  state.price = rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(2).toString()
  if (props.showCounter) {
    state.totalPrice = (Number(state.price) * props.count).toString()
  } else {
    state.totalPrice = state.price
  }
})

const loadImage = async () => {
  return await imagesStore.getImage(props.element.IMAGE_URL)
}

watch(() => props.element.IMAGE_URL, (newValue) => {
  if (newValue.length > 0) {
    loadImage().then(result => { imageSrc.value = result || '' })
  }
})

function toItem (item: Goods) {
  store.setItem(item)
  router.push({ name: 'item', params: { id: item.ID } })
}

function redFav (item: Goods) {
  emits('red-favorites', { good: item, store: 'favorites' })
}

function redChoosen (item: Goods) {
  emits('red-choosen', { good: item, store: 'choosen' })
}

function countPrice (res: CounterResult) {
  state.totalPrice = res.price.toString()
  emits('calc-total-price', res)
}
</script>

<style scoped>
.stop-style {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.stop-bg {
  font: 1.2rem 'Montserrat SemiBold', sans-serif;
  color: #fff;
  background-size: cover;
  border-radius: 1.7vmax;
  overflow: hidden;
  width: 15vw;
  height: 15vw;
  background-color: rgba(0, 0, 0, 0.60);
  position: absolute;
  z-index: 1500;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.stop-bg span {
  width: 12vw;
}
.img-loading {
  width: 15vw;
  height: 15vw;
  background-color: #dbdbdb;
  position: relative;
  background-size: cover;
  border-radius: 1.9vmax;
  overflow: hidden;
  animation: pulse 0.5s ease-in-out infinite alternate;
  margin-bottom: 1rem;
}

.text-loading {
  color: transparent;
  display: block;
  width: 10vw;
  height: 1.5rem;
  background-color: #dbdbdb;
  animation: pulse 0.5s ease-in-out infinite alternate;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.text-loading-price {
  color: transparent;
  display: block;
  width: 5vw;
  height: 1.5rem;
  background-color: #dbdbdb;
  animation: pulse 0.5s ease-in-out infinite alternate;
  border-radius: 0.5rem;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.miniature-container {
  display: flex;
  flex-flow: column nowrap;
  justify-items: space-between;
  max-width: 15vw;
  position: relative;
  /* gap: 1rem; */
}
.image-container {
  width: 15vw;
  height: 15vw;
  background-size: cover;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.7vmax;
  margin-bottom: 1rem;
}

/* .loading-image {
  width: 15vw;
  height: 15vw;
  border-radius: 1.7vmax;
  margin-bottom: 1rem;
  animation: flicker 1.5s infinite;
  background-color: #dadada;
} */

.good-img {
  width: 100vw;
  height: auto;
  background-size: cover;
  overflow: hidden;
}

.no-image {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-items: center;
  max-width: 20vmin;
  height: auto;
}

.no-image img {
  max-width: 80%;
}

.no-image-style {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2.5vw;
}

.good-btns {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 0.969rem;
  gap: 0.5rem;
}

.good-title {
  display: inline-block;
  position: relative;
  font: 1rem 'Montserrat Regular', sans-serif;
  color: var(--primary-font-color);
  height: 3.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-bottom: 0.15rem; */
}

.good-title::after {
   content: '';
   position: absolute;
   width: 100%;
   height: 30%;
   bottom: 0;
   right: 0;
   background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.good-price {
  font: 1.3rem 'Montserrat SemiBold', sans-serif;
  color: var(--primary-font-color);
  height: 1.5rem;
}

.good-price-counter {
  display: flex;
  justify-content: space-between;
}
.cursor {
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .miniature-container {
    max-width: 18vw;
  }
  .image-container {
    width: 18vw;
    height: 18vw;
    border-radius: 2.5vw;
  }
  .no-image {
    max-width: 20vmin;
  }
  .img-loading {
    width: 18vw;
    height: 18vw;
    border-radius: 2.5vw;
  }
  .stop-bg {
    border-radius: 2.5vw;
    width: 18vw;
    height: 18vw;
}
  .stop-bg span {
    width: 12vw;
  }
}

@media screen and (max-width: 1024px) {
  .no-image {
    max-width: 15vmin;
  }
}

@media screen and (max-width: 768px) {
  .miniature-container {
    max-width: 41vw;
    border-radius: 5.5vw;
  }
  .image-container {
    width: 41vw;
    height: 48vw;
    border-radius: 5.5vw;
  }
  .img-loading {
    width: 41vw;
    height: 48vw;
    margin-bottom: 0.1rem;
    border-radius: 5.5vw;
  }
  .text-loading {
    width: 35vw;
    margin-left: 2vw;
    margin-bottom: 2vw;
    margin-top: 4.5vw;
  }
  .text-loading-price {
    width: 15vw;
    margin-left: 2vw;
  }
  .good-title {
    font-size: 3.2vw;
    height: 9vw;
    padding-left: 2vw;
  }
  .good-title::after {
    height: 30%;
  }
  .good-price {
    font-size: 5vw;
    padding-left: 2vw;
  }
  .no-image {
    max-width: 25vmin;
    border-radius: 5.5vw;
  }
  .good-btns {
    margin: 3vw;
    gap: 2vw;
  }
  .stop-bg {
    border-radius: 5.5vw;
    width: 41vw;
    height: 48vw;
    text-align: center;
}
  .stop-bg span {
    width: 12vw;
  }
  .no-image-style {
    border-radius: 5.5vw;
  }
}
</style>
