<template>
  <div class="main-container">
    <button class="contact-btn" @click="toTop">
      <img src="@/assets/icons/to-top.svg">
    </button>
    <u-header @click-categories="openCatSelector" :isLoaded="state.isLoaded" :isFavEmpty="state.isFavEmpty" :showLogo="true" :showRestaurantName="true"/>
    <!-- <div class="main-content-section"> -->
      <div class="main-content-section" v-if="!state.catIsSelected">
        <div class="header-text-selected-cat">
          <button class="header-text category-click button-click" @click="openCatSelector">Категории</button>
        </div>
        <MainSwiper :type="'categories'" @categories-loaded="categoriesLoaded" :catId="''" @choose-category="toggleCatSelector"/>
      </div>
      <div v-else @click="toggleCatSelector('')">
        <button class="header-text category-click button-click">
          <span class="header-text header-text-selected-cat-part">Блюда / </span>
          <span class="header-text header-text-selected-cat">{{ state.catTitle }}</span>
        </button>
      </div>
    <!-- </div> -->
    <div class="main-content-section goods-container">
      <h2 v-if="!state.catIsSelected" class="header-text">Блюда</h2>
      <MainSwiper @fav-empty="updateGoodFav" :type="'goods'" :catId="('ID' in state.category ? state.category.ID : '')"/>
    </div>
  </div>
  <transition name="fade" @before-leave="beforeFadeLeave">
    <div v-if="state.showCatSelector" class="fade" @click="toggleCatSelector('')"></div>
  </transition>
  <transition name="dialog">
    <u-category-selector
      v-if="state.showCatSelector"
      :categories="state.categories"
      @click-category="toggleCatSelector"
      class="dialog"
    />
  </transition>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MainSwiper from './MainSwiper.vue'
import { Category } from '@/store'
import { delay, rebuildString } from '../globalFunctions'

const state = reactive({
  categories: [] as Category[],
  showCatSelector: false,
  catIsSelected: false,
  catTitle: '',
  category: {} as Category | object,
  isFavEmpty: false,
  isLoaded: false
})

onBeforeMount(async () => {
  await delay(1000)
  state.isLoaded = true
})

function categoriesLoaded (arr: Category[]) {
  state.categories = arr
}

function toggleCatSelector (catId: string = '') {
  if (catId === '') {
    state.catIsSelected = false
    state.category = {}
  } else {
    const category = state.categories.find(item => item.ID === catId)
    state.catIsSelected = true
    state.category = category || {}
    state.catTitle = category ? rebuildString(category.TITLE) : ''
  }
  state.showCatSelector = false
}

function updateGoodFav (arg: boolean = false) {
  state.isFavEmpty = arg
}

async function beforeFadeLeave (el: Element) {
  el.classList.add('fade-out')
  await delay(500)
}

function openCatSelector () {
  state.showCatSelector = true
}

function toTop () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style scoped>
.contact-btn {
  background-color: #fff;
  border: 1px #ddd solid;
  border-radius: 50%;
  width: 7vh;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2999;
  bottom: 3rem;
  right: 3rem;
  cursor: pointer;
}
.contact-btn img {
  width: 1.5rem;
}
.main-container {
  margin-top: 8rem;
}
.main-content-section {
  margin-bottom: 1.5rem;
}
.header-text {
  font: 1.7rem 'Montserrat SemiBold', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.header-text-selected-cat-part {
  font: 1.7rem 'Montserrat Medium', sans-serif;
}

.categories {
  display: flex;
}

.fade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s forwards;
  z-index: 3000;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dialog {
  width: 25%;
}
.dialog-enter-active, .dialog-leave-active {
  transition: transform 0.5s ease-in-out;
  width: 25%;
}

.dialog-enter-from, .dialog-leave-to {
  transform: translateX(-100%);
  width: 25%;
}

.dialog-enter-to, .dialog-leave-from {
  transform: translateX(0);
  width: 25%;
}

.header-text-selected-cat {
  cursor: pointer;
}

.header-text-selected-cat-part {
  color: #AAAAAA;
  margin-bottom: 2rem;
}

.category-click {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header-text {
    font-size: 5vw;
  }
  .main-container {
    margin-top: 22vw;
  }
  .contact-btn {
    width: 10vw;
    height: 10vw;
    bottom: 10vw;
    right: 6vw;
  }
  .contact-btn img {
    width: 4vw;
  }
}
</style>
