<template>
  <div class="goods-header">
    <!-- <u-icon-rounded :type="'back'" @click="router.push({ name: 'main' })" :iconWidth="0.75" :iconUnit="'rem'" :iconMnRt="0.15" :iconMnUnit="'rem'" /> -->
    <u-header :isLoaded="true" :showBackBtn="true" :showLogo="false" :showRestaurantName="false" :showText="false" />
  </div>
  <div class="item-container">
    <div class="item-left-side">
      <img :src="`data:image/jpeg;base64, ${imageSrc}`" class="item-left-side-img"
        v-if="imageSrc !== ''">
      <img :src="noImage" class="item-left-side-img" v-else>
    </div>
    <div class="item-right-side">
      <div class="item-info-container">
        <div class="item-info-container-info">
          <span class="item-title">{{ state.title }}</span>
          <span class="item-description">{{ state.item?.DESCRIPTION }}</span>
        </div>
        <div class="item-price-block">
          <div class="item-price-counter-block">
            <span class="item-price">{{ state.totalPrice }}с.</span>
            <u-counter class="item-price" :counter="1" :price="Number(state.price)" :id="state.id" @ucounter-click="getText" />
          </div>
          <u-button class="order-btn" :btnText="'Добавить в заказ'" @btn-click="openDialog('Добавить в заказ?')" />
        </div>
      </div>
    </div>
  </div>
  <u-dialog v-if="state.showDialog" :header=state.dialogHeader :msgText=state.dialogText @click-res="closeDialog"
    :cancelButton="true" />
  <u-listner @is-mobile="isMobile" />
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, ref, watch } from 'vue'
import { useStore, Goods } from '../store/index'
import { delay, roundPrice } from '../globalFunctions'
import { CounterResult } from './global/UCounter.vue'
import { useRouter, useRoute } from 'vue-router'
import noImage from '../assets/images/no_image.svg'
import { eventBus } from '@/eventBus'
import { useImagesYarosStore } from '@/store/images'

const router = useRouter()

const imagesStore = useImagesYarosStore()
const imageSrc = ref('')

interface DialogText {
  title: string;
  total_sum: number;
  counter: number;
}

const loadImage = async () => {
  return await imagesStore.getImage(state.item?.IMAGE_URL ? state.item?.IMAGE_URL : '')
}

watch(() => state.item?.IMAGE_URL, (newValue) => {
  if (newValue && newValue.length > 0) {
    loadImage().then(result => { imageSrc.value = result || '' })
  }
})

export interface OrderItem {
  item: Goods;
  count: number;
}

const store = useStore()
const route = useRoute()

const state = reactive({
  orgId: '',
  item: store.getCurrentItem,
  dialogText: '',
  dialogHeader: '',
  showDialog: false,
  result: {
    title: '',
    total_sum: store.getCurrentItem?.PRICE || 0,
    counter: 1
  } as DialogText,
  isMobile: false,
  price: '',
  title: '',
  id: '',
  totalPrice: ''
})

onBeforeMount(async () => {
  state.orgId = await JSON.parse(sessionStorage.getItem('orgId') || '""')
  const idParam = route.params.id
  state.id = Array.isArray(idParam) ? idParam[0] : idParam
  if (!state.item) {
    await store.getGoodAction(state.orgId, state.id)
    state.item = store.getGood
  }
  state.title = state.item ? state.item.TITLE.replace(/^[._]+/, '') : ''
  state.totalPrice = roundPrice(state.item?.PRICE)
  state.price = state.totalPrice
})

function isMobile (res: boolean) {
  state.isMobile = res
}

function openDialog (dialogHeader: string) {
  state.dialogHeader = dialogHeader
  state.dialogText = `${state.result.title} <b>${state.result.counter}</b>шт на сумму <b>${roundPrice(state.result.total_sum)}c.</b>`
  state.showDialog = true
}

async function closeDialog (res: string) {
  await delay(200)
  state.showDialog = false
  if (res === 'ok' && state.id && state.item) {
    let orders = JSON.parse(sessionStorage.getItem('orders') || '[]')
    if (!Array.isArray(orders)) {
      orders = []
    }
    if (state.item) {
      const goodIndex = orders.findIndex((order: OrderItem) => order.item.ID === state.item?.ID)
      if (goodIndex !== -1) {
        orders[goodIndex].count += state.result.counter
      } else {
        orders.push({ item: state.item, count: state.result.counter })
      }
      sessionStorage.setItem('orders', JSON.stringify(orders))
      eventBus.emit('changed-store')
    }
  }
  router.push({ name: 'main' })
}

function getText (res: CounterResult) {
  state.totalPrice = res.price.toString()
  state.result = { title: state.title, total_sum: res.price, counter: res.counter }
}
</script>

<style scoped>
.goods-header {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 3rem;
}
.item-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-height: 100vh;
  position: relative;
}
.item-left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  height: auto;
  overflow-y: hidden;
}
.item-left-side-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.item-right-side {
  display: flex;
  flex-flow: column nowrap;
  width: 45%;
  height: auto;
  border-radius: 3.125rem;
  padding: 3.125rem;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
}
.item-info-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
  margin-top: 5.25rem;
}
.item-info-container-info {
  display: flex;
  flex-flow: column nowrap;
}
.item-title, .item-price {
  font: 2rem 'Montserrat Bold', sans-serif;
  color: var(--primary-font-color);
}
.item-description {
  font: 1.4rem 'Montserrat Regular', sans-serif;
  color: var(--descr-font-color);
  margin-left: 0.1rem;
}
.item-price-counter-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.header-container {
  margin-bottom: 0!important;
}
.icons-container {
  gap: 0!important;
}
.item-title {
  margin-bottom: 1rem;
}
@media screen and (max-width: 768px) {
  .goods-header {
    margin: 7vw;
  }
  .item-container {
    flex-flow: column nowrap;
  }
  .item-left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 50%;
    overflow-y: hidden;
    position: relative;
  }
  .item-left-side-img {
    width: 100%;
    height: 50vh;
    margin-bottom: 50vh;
    object-fit: cover;
  }
  .item-right-side {
    display: flex;
    flex-flow: column nowrap;
    width: auto;
    height: 50%;
    border-radius: 12vw 12vw 0 0;
    padding: 7vw;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .item-title, .item-price {
    font-size: 5.3vw;
  }
  .item-title {
    margin-bottom: 2vw;
  }
  .item-info-container {
    height: 100%;
    margin-top: 3vw;
  }
  .item-description {
    font-size: 1rem;
  }
}
</style>
