<template>
    <div class="order-container">
      <div class="order-header">
        <u-header :isLoaded="true" :showLogo="true" :showRestaurantName="true" :isFavEmpty="state.favorites.length === 0"/>
      </div>
    <div class="order-wrapper">
      <div class="order-leftside">
        <div class="order-leftside-container">
        <span class="order-header-list">Заказ</span>
        <div v-if="state.items.length > 0" class="goods-list">
          <div v-for="good in state.items" :key="good.item.ID">
            <u-good-miniature :isLoaded="state.isLoaded" :element="good.item" :showCounter="true" :count="good.count" @calc-total-price="calcOrder"/>
          </div>
        </div>
        <div v-else class="empty-order">
            <span>Заказ пока пуст...</span>
        </div>
        </div>
      </div>
      <div class="order-rightside">
        <div class="order-rightside-container">
          <div class="order-rows">
            <div class="order-row-container">
              <span class="order-row">Сумма заказа</span>
              <span class="order-row-result">{{ state.orderSum }}с.</span>
            </div>
            <div class="order-row-container">
              <span class="order-row">Обслуживание</span>
              <span class="order-row-result">{{ state.service }}%</span>
            </div>
            <div class="order-row-container">
              <span class="order-row">Общая сумма</span>
              <span class="order-row-result-sum">{{ state.totalPrice }}с.</span>
            </div>
          </div>
          <!-- <u-button :btnText="'Оформить заказ'" class="order-btn"/> -->
        </div>
      </div>
    </div>
  </div>
  <u-listner @is-mobile="isMobile" @org-info="state.orgInfo"/>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { Goods } from '../store/index'
import { OrderItem } from './ItemView.vue'
import { CounterResult } from './global/UCounter.vue'
import { rgbToHex } from '../globalFunctions'
import { Organization } from '@/store/organization'

const baseColor = process.env.VUE_APP_ACTIVE_COLOR

const state = reactive({
  orderSum: 0,
  service: 0,
  totalPrice: 0,
  items: [] as Array<OrderItem>,
  favorites: [] as Array<Goods>,
  orgInfo: {} as Organization | object,
  isMobile: false,
  isLoaded: false,
  activeColor: baseColor
})

onBeforeMount(() => {
  state.favorites = JSON.parse(sessionStorage.getItem('favorites') || '[]')
  state.items = JSON.parse(sessionStorage.getItem('orders') || '[]')
  calcOrder(null)
  state.isLoaded = true
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  state.service = org.servicePercent
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== null ? activeColor : baseColor
  document.documentElement.style.setProperty('--your-active-color-variable', state.activeColor)
})

function calcOrder (calc: CounterResult | null) {
  state.orderSum = 0
  if (calc) {
    state.items = state.items.map(item => {
      if (item.item.ID === calc.id) {
        return { ...item, count: calc.counter }
      }
      return item
    })
  }
  for (const good of state.items) {
    state.orderSum += Number(good.item.PRICE) * good.count
  }
  sessionStorage.setItem('orders', JSON.stringify(state.items))
  state.totalPrice = state.orderSum + state.orderSum * (state.service / 100)
}

function isMobile (arg: boolean) {
  state.isMobile = arg
}
</script>

<style scoped>
.order-header {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 3rem 3rem 3rem;
}
.order-container {
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* margin: 8.5rem 3rem 3rem 3rem; */
  margin: 0 3rem;
}
.order-leftside {
  width: 58vw;
  height: fit-content;
  margin: 8.5rem 0 3rem 0;
}
.order-rightside {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 44vw;
  max-height: 100vh;
  box-shadow: -2px 0px 2px 0px rgba(153, 153, 153, 0.25);
  border-radius: 3.125rem 0 0 3.125rem;
}
.order-rightside-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
  margin: 8.5rem 3rem 3.75rem 3rem;
}
.order-leftside-container {
  height: 100%;
  /* margin: 8.5rem 3rem 3.75rem 3rem; */
}
.order-rows {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.875rem;
}
.order-row-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
}
.order-row {
  font: 2rem 'Montserrat Medium', sans-serif;
  color: var(--primary-font-color);
  margin-right: 0.5em;
}
.order-row-result {
  font: 2rem 'Montserrat Bold', sans-serif;
  color: var(--primary-font-color);
}
.order-row-result-sum {
  font: 2rem 'Montserrat Bold', sans-serif;
  color: var(--your-active-color-variable);
}
.order-header-list {
  font: 2rem 'Montserrat SemiBold', sans-serif;
  color: var(--primary-font-color);
  margin-bottom: 2rem;
}
.empty-order {
  font: 1.5rem 'Montserrat Regular', sans-serif;
  color: var(--side-font-color);
  margin-top: 2rem;
}
.order-btn {
  margin-top: 2rem;
}
.goods-list {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .order-header {
    margin: 7vw;
  }
  .order-container {
    margin: 0 7vw;
  }
  .order-rows {
    gap: 4.5vw;
  }
  .order-row {
    font-size: 5vw;
    margin-right: 0.5em;
  }
  .order-row-result {
    font-size: 5vw;
  }
  .order-row-result-sum {
    font-size: 5vw;
  }
  .order-header-list {
    font-size: 5vw;
    margin-bottom: 2vw;
  }
  .empty-order {
    font-size: 3.5vw;
    margin-top: 2vw;
  }
  .order-leftside {
    width: fit-content;
    height: auto;
    margin: 22vw 0 6vw 0;
  }
  .order-rightside {
    position: fixed;
    z-index: 2000;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 3rem 3rem 0 0;
    box-shadow: 0px 0px 1px 1px rgba(153, 153, 153, 0.25);
  }
  .order-rightside-container {
    height: auto;
    margin: 7vw;
  }
  .goods-list {
    gap: 3vw;
    margin-top: 3.5vw;
    /* margin-bottom: 65vw; раскомментить, когда вернется кнопка заказа */
    margin-bottom: 45vw;
  }
  .goods-list div {
    margin-bottom: 2vw;
  }
  .order-wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }
}
</style>
