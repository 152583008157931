import { defineStore } from 'pinia'
import axios, { AxiosError } from 'axios'

export const useImagesYarosStore = defineStore('imagesYaros', {
  state: () => ({
    image: '' as string
  }),
  actions: {
    async getImage (store: string, id: string = '') {
      try {
        const encodedStore = encodeURIComponent(store)
        const response = await axios.get<string>('/@image_store', {
          params: {
            store: encodedStore,
            id
          }
        })
        return response.data ? response.data : ''
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError.message)
      }
    }
  }
})
