import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import { registerGlobalComponents } from './views/global'
import './assets/animations.css'
import './session-storage-types'

const app = createApp(App)
const pinia = createPinia()

axios.defaults.withCredentials = true
axios.defaults.timeout = 20000
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common.Authorization = 'Basic VGVzdEFQSTp0ZXN0'

axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Axios request failed:', error)
    return Promise.reject(error)
  }
)

registerGlobalComponents(app)

app.config.globalProperties.$sessionStorage = {
  setItem (key: string, value: unknown) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  getItem (key: string) {
    const value = sessionStorage.getItem(key)
    return value ? JSON.parse(value) : null
  },
  removeItem (key: string) {
    sessionStorage.removeItem(key)
  },
  clear () {
    sessionStorage.clear()
  }
}

app.use(pinia)
app.use(router)
app.mount('#app')
