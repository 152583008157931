<template>
  <div>
    <button @click="emits('btn-click')" class="btn button-click" :style="{ color: state.activeColor || baseColor, borderColor: state.activeColor || baseColor }">
        <span class="btn-text" :style="{ color: state.activeColor || baseColor, borderColor: state.activeColor || baseColor }">{{ props.btnText }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { rgbToHex } from '../../globalFunctions'

interface State {
  activeColor: string;
}

const baseColor = process.env.VUE_APP_ACTIVE_COLOR

const props = defineProps<{
  btnText: string
}>()

const emits = defineEmits(['btn-click'])

const state = reactive<State>({
  activeColor: baseColor
})

onBeforeMount(async () => {
  const org = JSON.parse(sessionStorage.getItem('organization') || '{}')
  const activeColor = rgbToHex(org.colorOfActivity)
  state.activeColor = activeColor !== '' ? activeColor : baseColor
})
</script>

<style>
.btn {
  width: 100%;
  border-radius: 2.5rem;
  border: 0.25rem solid;
  padding: 1.2rem;
  background-color: transparent;
  cursor: pointer;
}

.btn-text {
  font: 1.6rem 'Montserrat SemiBold', sans-serif;
}

@media screen and (max-width: 768px) {
  .btn {
    padding: 3.6vw;
    border: 0.8vw solid;
    border-radius: 3rem;
  }
  .btn-text {
    font-size: 5vw;
  }
}
</style>
